<template>
  <div class="wrap">
    <h1>ipaas.paigod.work</h1>
    <div class="banner">
      <img src="../assets/banner.png" alt="" />
    </div>
    <div class="page">
      <h2>Dude,,we can't find that page!</h2>
    </div>
    <div class="footer">
      <p><a href="http://ipaas.paigod.work">http://ipaas.paigod.work</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>
a{text-decoration:none;}
.txt-rt{text-align:right;}/* text align right */
.txt-lt{text-align:left;}/* text align left */
.txt-center{text-align:center;}/* text align center */
.float-rt{float:right;}/* float right */
.float-lt{float:left;}/* float left */
.clear{clear:both;}/* clear float */
.pos-relative{position:relative;}/* Position Relative */
.pos-absolute{position:absolute;}/* Position Absolute */
.vertical-base{	vertical-align:baseline;}/* vertical align baseline */
.vertical-top{	vertical-align:top;}/* vertical align top */
.underline{	padding-bottom:5px;	border-bottom: 1px solid #eee; margin:0 0 20px 0;}/* Add 5px bottom padding and a underline */
nav.vertical ul li{	display:block;}/* vertical menu */
nav.horizontal ul li{	display: inline-block;}/* horizontal menu */
img{max-width:100%;}
/*end reset*/
body{
  font-family:"Century Gothic",serif;
  background: url('../assets/bg.png');
}

.wrap{
  margin:0 auto;
  width: 96%;
}
h1{
  margin-top: 20px;
  color: #603813;
  font-size: 3em;
  text-transform: uppercase;
  font-weight: bold;
}
.banner{
  text-align:center;
  margin-top: 30px;
}
.banner img{
  margin-top: 0px;
}
.page{
  text-align:center;
  font-family: "Century Gothic";
}
.page h2{
  font-size:3em;
  color: rgb(99, 44, 37);
  font-weight:bold;
}
.footer{
  font-family: "Century Gothic";
  position: absolute;
  right: 30px;
  bottom:20px;
}
.footer p{
  font-size:1em;
  color: #603813;
}
.footer a{
  color: #f9614d;
}
.footer a:hover{
  text-decoration:underline;
}
/*media queries*/
@media all and (max-width:1366px) and (min-width:1280px){
  .wrap{
    width: 90%;
  }
  .banner{
    margin-top: 30px;
  }
}
@media all and (max-width:1280px) and (min-width:1024px){
  .wrap{
    width: 90%;
  }
}
@media all and (max-width:1024px) and (min-width:800px){
  .wrap{
    width: 90%;
  }
  h1{
    font-size: 2em;
  }
  .banner{
    margin-top: 0px;
  }
  .page h2{
    font-size: 2em;
  }
}
@media all and (max-width:800px) and (min-width:640px){
  .wrap{
    width: 90%;
  }
  h1{
    font-size: 2em;
  }
  .banner{
    margin-top: 10px;
  }
  .banner img{
    width: 34%;
  }
  .page h2{
    font-size:2em;
  }
}
@media all and (max-width:640px) and (min-width:480px){
  .wrap{
    width: 90%;
  }
  h1{
    font-size: 1.6em;
  }
  .banner{
    margin-top: 0px;
  }
  .banner img{
    width: 32%;
  }
  .page h2{
    font-size:1.6em;
  }
}
@media all and (max-width:480px) and (min-width:320px){
  .wrap{
    width: 90%;
  }
  h1{
    font-size: 1.4em;
  }
  .banner{
    margin-top: 0px;
  }
  .banner img{
    width: 32%;
  }
  .page h2{
    font-size:1.4em;
  }
  .footer p{
    font-size: 0.9em;
  }
}
@media all and (max-width:320px){
  .wrap{
    width: 90%;
  }
  h1{
    font-size: 1.4em;
  }
  .banner{
    margin-top: 10px;
  }
  .banner img{
    width:80%;
  }
  .page h2{
    font-size:1.4em;
  }
  .footer{
    bottom: 10px;
  }
  .footer p{
    font-size: 0.9em;
  }
}
</style>